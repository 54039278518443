
  import { Component, Prop } from 'vue-property-decorator'
  import StatusCell from '@/components/dataTables/cell/StatusCell.vue'
  import Chip from '@/components/toolkit/details/row/chip.vue'
  import dayjs from 'dayjs'
  import { dateToLocal } from '@/utils/general'
  import { GForm } from '@/components/forms/GForm'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: {
    GAlert,
    Chip,
    StatusCell,
    Row: () => import('../simple.vue'),
  },
})
  export default class CavValidation extends GForm {
  @Prop({ type: [String, Number, Object, Array] }) value!: any
  @Prop({ type: String, default: '' }) title: string
  @Prop({ type: Boolean, default: false }) supervisor: boolean

  config = null
  panel? = -1
  alert = {
    open: false,
    title: 'Al validar este CAV das por correcta la información contenida',
  }

  getIcon (val: boolean | null) {
    if (val === null) {
      return 'mdi-alert'
    }

    return val ? 'mdi-check-circle' : 'mdi-alert-circle'
  }

  getColor (val: boolean | null) {
    if (val === null) {
      return 'yellow'
    }
    return val ? 'green' : 'red'
  }

  get allTrue () {
    const { validations } = this
    return validations.every(val => val.value)
  }

  get bind () {
    const { expirationDate } = this
    const today = dayjs()
    const isExpired = dayjs(expirationDate).isValid() && today.isAfter(expirationDate)

    return {
      value: expirationDate.format('DD/MM/YYYY'),
      color: isExpired ? 'red' : 'gray',
      outlined: true,
      tooltip: 'Válido hasta',
    }
  }

  get validDate () {
    const { expirationDate } = this

    return dayjs(expirationDate).isValid()
  }

  get validations () {
    const { value } = this
    if (!value.validations.length) return []

    return value?.validations
  }

  get expirationDate () {
    const { value } = this

    return dateToLocal(value?.expirationDate.endOf('day'))
  }
  }
